/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import  { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
// import ReactSession from 'react-client-session';
import { getPhysician, getPaymentInfo,StoragePlan,verifyClientData   } from '../../actions/payActions'
import NumberFormat from 'react-number-format';


class ActBankingStrorage extends Component {

  constructor(props){
    super(props);
    this.state={
        error:'',
        errors:{},
        formData:{Annual_Storage_Plan:"No Annual Storage",amount:4250,Plan:"Bio-Insurance Plan",Vials:10,Bio_Insurance_Banking_Plans:"ACT Bio-Insurance",Annual_Storage_Plan_Amount:0,Banking_Plan_Amount:4250,CreditCard:true,PaymentMethod:"Credit Card",Source:"ACT Physician Portal",Payment_Type:"CC",CreditCardProcessingFee:0.03*4250 },
        login:true,                       
        client_test:"",
        client_info:"",
        Dob:'',
        ShipmentDate:"",
        card_last_gid:"",
        StoragePlan:"",
        card_info:{},
        save_card:false,
        save_ach:false,
        update_card:false,
        loading:true,
        page:0,
        amount:4250,
        additional_plan:4250,
        storage_plan:0,
        plan:"Bio-Insurance Plan",
        verify_client:false,
        loggedIn:false,
        disableField:false,
        CreditCard:true,
        credit_card:true,
        vials_count:10,
        Payment_Type:"CC",
        update_ach:false,
        vials:[ 
          {
           "Vials": 10,
           "Price": 4250,
           "Discount": 15,
           "Total Savings": 750
          },
          {
           "Vials": 11,
           "Price": 4675,
           "Discount": 15,
           "Total Savings": 825
          },
          {
           "Vials": 12,
           "Price": 5100,
           "Discount": 15,
           "Total Savings": 900
          },
          {
           "Vials": 13,
           "Price": 5525,
           "Discount": 15,
           "Total Savings": 975
          },
          {
           "Vials": 14,
           "Price": 5950,
           "Discount": 15,
           "Total Savings": 1050
          },
          {
           "Vials": 15,
           "Price": 6375,
           "Discount": 15,
           "Total Savings": 1125
          },
          {
           "Vials": 16,
           "Price": 6800,
           "Discount": 15,
           "Total Savings": 1200
          },
          {
           "Vials": 17,
           "Price": 7225,
           "Discount": 15,
           "Total Savings": 1275
          },
          {
           "Vials": 18,
           "Price": 7650,
           "Discount": 15,
           "Total Savings": 1350
          },
          {
           "Vials": 19,
           "Price": 8075,
           "Discount": 15,
           "Total Savings": 1425
          },
          {
           "Vials": 20,
           "Price": 8500,
           "Discount": 15,
           "Total Savings": 1500
          },
          {
           "Vials": 21,
           "Price": 8925,
           "Discount": 15,
           "Total Savings": 1575
          },
          {
           "Vials": 22,
           "Price": 9350,
           "Discount": 15,
           "Total Savings": 1650
          },
          {
           "Vials": 23,
           "Price": 9775,
           "Discount": 15,
           "Total Savings": 1725
          },
          {
           "Vials": 24,
           "Price": 10200,
           "Discount": 15,
           "Total Savings": 1800
          },
          {
           "Vials": 25,
           "Price": 10000,
           "Discount": 2,
           "Total Savings": 2500
          },
          {
           "Vials": 26,
           "Price": 10400,
           "Discount": 2,
           "Total Savings": 2600
          },
          {
           "Vials": 27,
           "Price": 10800,
           "Discount": 2,
           "Total Savings": 2700
          },
          {
           "Vials": 28,
           "Price": 11200,
           "Discount": 2,
           "Total Savings": 2800
          },
          {
           "Vials": 29,
           "Price": 11600,
           "Discount": 2,
           "Total Savings": 2900
          },
          {
           "Vials": 30,
           "Price": 12000,
           "Discount": 2,
           "Total Savings": 3000
          },
          {
           "Vials": 31,
           "Price": 12400,
           "Discount": 2,
           "Total Savings": 3100
          },
          {
           "Vials": 32,
           "Price": 12800,
           "Discount": 2,
           "Total Savings": 3200
          },
          {
           "Vials": 33,
           "Price": 13200,
           "Discount": 2,
           "Total Savings": 3300
          },
          {
           "Vials": 34,
           "Price": 13600,
           "Discount": 2,
           "Total Savings": 3400
          },
          {
           "Vials": 35,
           "Price": 14000,
           "Discount": 2,
           "Total Savings": 3500
          },
          {
           "Vials": 36,
           "Price": 14400,
           "Discount": 2,
           "Total Savings": 3600
          },
          {
           "Vials": 37,
           "Price": 14800,
           "Discount": 2,
           "Total Savings": 3700
          },
          {
           "Vials": 38,
           "Price": 15200,
           "Discount": 2,
           "Total Savings": 3800
          },
          {
           "Vials": 39,
           "Price": 15600,
           "Discount": 2,
           "Total Savings": 3900
          },
          {
           "Vials": 40,
           "Price": 16000,
           "Discount": 2,
           "Total Savings": 4000
          },
          {
           "Vials": 41,
           "Price": 16400,
           "Discount": 2,
           "Total Savings": 4100
          },
          {
           "Vials": 42,
           "Price": 16800,
           "Discount": 2,
           "Total Savings": 4200
          },
          {
           "Vials": 43,
           "Price": 17200,
           "Discount": 2,
           "Total Savings": 4300
          },
          {
           "Vials": 44,
           "Price": 17600,
           "Discount": 2,
           "Total Savings": 4400
          },
          {
           "Vials": 45,
           "Price": 18000,
           "Discount": 2,
           "Total Savings": 4500
          },
          {
           "Vials": 46,
           "Price": 18400,
           "Discount": 2,
           "Total Savings": 4600
          },
          {
           "Vials": 47,
           "Price": 18800,
           "Discount": 2,
           "Total Savings": 4700
          },
          {
           "Vials": 48,
           "Price": 19200,
           "Discount": 2,
           "Total Savings": 4800
          },
          {
           "Vials": 49,
           "Price": 19600,
           "Discount": 2,
           "Total Savings": 4900
          },
          {
           "Vials": 50,
           "Price": 18750,
           "Discount": 25,
           "Total Savings": 6250
          },
          {
           "Vials": 51,
           "Price": 19125,
           "Discount": 25,
           "Total Savings": 6375
          },
          {
           "Vials": 52,
           "Price": 19500,
           "Discount": 25,
           "Total Savings": 6500
          },
          {
           "Vials": 53,
           "Price": 19875,
           "Discount": 25,
           "Total Savings": 6625
          },
          {
           "Vials": 54,
           "Price": 20250,
           "Discount": 25,
           "Total Savings": 6750
          },
          {
           "Vials": 55,
           "Price": 20625,
           "Discount": 25,
           "Total Savings": 6875
          },
          {
           "Vials": 56,
           "Price": 21000,
           "Discount": 25,
           "Total Savings": 7000
          },
          {
           "Vials": 57,
           "Price": 21375,
           "Discount": 25,
           "Total Savings": 7125
          },
          {
           "Vials": 58,
           "Price": 21750,
           "Discount": 25,
           "Total Savings": 7250
          },
          {
           "Vials": 59,
           "Price": 22125,
           "Discount": 25,
           "Total Savings": 7375
          },
          {
           "Vials": 60,
           "Price": 22500,
           "Discount": 25,
           "Total Savings": 7500
          },
          {
           "Vials": 61,
           "Price": 22875,
           "Discount": 25,
           "Total Savings": 7625
          },
          {
           "Vials": 62,
           "Price": 23250,
           "Discount": 25,
           "Total Savings": 7750
          },
          {
           "Vials": 63,
           "Price": 23625,
           "Discount": 25,
           "Total Savings": 7875
          },
          {
           "Vials": 64,
           "Price": 24000,
           "Discount": 25,
           "Total Savings": 8000
          },
          {
           "Vials": 65,
           "Price": 24375,
           "Discount": 25,
           "Total Savings": 8125
          },
          {
           "Vials": 66,
           "Price": 24750,
           "Discount": 25,
           "Total Savings": 8250
          },
          {
           "Vials": 67,
           "Price": 25125,
           "Discount": 25,
           "Total Savings": 8375
          },
          {
           "Vials": 68,
           "Price": 25500,
           "Discount": 25,
           "Total Savings": 8500
          },
          {
           "Vials": 69,
           "Price": 25875,
           "Discount": 25,
           "Total Savings": 8625
          },
          {
           "Vials": 70,
           "Price": 26250,
           "Discount": 25,
           "Total Savings": 8750
          },
          {
           "Vials": 71,
           "Price": 26625,
           "Discount": 25,
           "Total Savings": 8875
          },
          {
           "Vials": 72,
           "Price": 27000,
           "Discount": 25,
           "Total Savings": 9000
          },
          {
           "Vials": 73,
           "Price": 27375,
           "Discount": 25,
           "Total Savings": 9125
          },
          {
           "Vials": 74,
           "Price": 27750,
           "Discount": 25,
           "Total Savings": 9250
          },
          {
           "Vials": 75,
           "Price": 28125,
           "Discount": 25,
           "Total Savings": 9375
          }
         ],
    }
}

numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'USD'
    }).format(value);


componentDidMount() {          
  var physician_id = localStorage.getItem('physician_id'); 
  
  if(physician_id && physician_id != null){
    this.setState({loggedIn:true})
      this.props.getPhysician(physician_id, this.props.history);    
  this.props.getPaymentInfo(physician_id, this.props.history);
  } 
      
   
  var physician_id = localStorage.getItem('physician_id');
  if(physician_id != null){
      // console.log('imrn')
      this.setState({'physician_id':physician_id})
     
      // console.log('physician_id ----------> ',physician_id )
      
  }
          
}
  componentWillReceiveProps(nextProps) {
    

    if(nextProps.page_name.pay_info && this.state.update_card==false){
        this.state.formData['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
        this.state.formData['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
        this.state.formData['Credit_Card_Billing_Zip_Code'] = nextProps.page_name.pay_info.Credit_Card_Billing_Zip_Code__c
        this.state.formData['CVV'] = nextProps.page_name.pay_info.CVV__c
        this.state.formData['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
        this.state.formData['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
        this.state.formData['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
        this.setState({Credit_Card_Number:nextProps.page_name.pay_info.Credit_Card_Number__c})
        this.state.formData['PhysicianFirstName'] = nextProps.page_name.pay_info.FirstName
        this.state.formData['PhysicianLastName'] = nextProps.page_name.pay_info.LastName
               // this.setState({card_info:nextProps.page_name.pay_info})
        this.state.card_info['Credit_Card_Number'] = nextProps.page_name.pay_info.Credit_Card_Number__c
        this.state.card_info['CVV'] = nextProps.page_name.pay_info.CVV__c
        this.state.card_info['Credit_Card_Holder_First_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_First_Name__c
        this.state.card_info['Credit_Card_Holder_Last_Name'] = nextProps.page_name.pay_info.Credit_Card_Holder_Last_Name__c
        this.state.card_info['Month_of_Expiry'] = nextProps.page_name.pay_info.Month_of_Expiry__c
        this.state.card_info['Year_of_Expiry'] = nextProps.page_name.pay_info.Year_of_Expiry__c
        this.state.card_info['PhysicianFirstName'] = nextProps.page_name.pay_info.FirstName
        this.state.card_info['PhysicianLastName'] = nextProps.page_name.pay_info.LastName
        this.state.card_info['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
        this.state.card_info['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c
        this.state.card_info['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c



      
        
      if(nextProps.page_name.pay_info.Account_Number__c){
          var acc_num = nextProps.page_name.pay_info.Account_Number__c;
          this.state.acc_num = acc_num.slice(acc_num.length - 4)
          this.state.formData['Account_Number'] = nextProps.page_name.pay_info.Account_Number__c
      }

      if(nextProps.page_name.pay_info.ABA_Routing_Number__c){
          this.state.formData['ABA_Routing_Number'] = nextProps.page_name.pay_info.ABA_Routing_Number__c
      }
      
      if(nextProps.page_name.pay_info.Name_on_ACH_Account__c){
          this.state.formData['Name_On_Account'] = nextProps.page_name.pay_info.Name_on_ACH_Account__c
      }


        if(nextProps.page_name.pay_info.Credit_Card_Number__c){
          var nu =  nextProps.page_name.pay_info.Credit_Card_Number__c
          this.state.card_last_gid = nu.slice(nu.length - 4)
        }

        // console.log('credit card => ',this.state.card_last_gid)
        // console.log('form data', this.state.card_info)
        this.setState({loading:false})
        window.scrollTo(0, 0);
      } 

      if(nextProps.page_name.page_name){
        // console.log('nextProp = >>>>>> ',nextProps.page_name.page_name)
        this.setState({loading:false})
        this.state.formData['PhysicianId'] = nextProps.page_name.page_name.Id
        this.state.formData['Id'] = nextProps.page_name.page_name.Physician_ID__c
        this.state.formData['PhysicianLastName'] = nextProps.page_name.page_name.LastName 
        
        this.setState({loading:false})
        window.scrollTo(0, 0);            
    }

    if(nextProps.page_name.payment_info){

        this.setState({loading:false})
        
        if(nextProps.page_name.payment_info.error){
          this.setState({api_error:nextProps.page_name.payment_info.error})
        }else{
          this.state.formData['TransactionId'] = nextProps.page_name.payment_info.TransactionId
          this.setState({payment_response:nextProps.page_name.payment_info.page_name})
        }
        
        this.setState({loading:false})
        window.scrollTo(0, 0);     

    }

    
    if(nextProps.page_name.client_info){
      this.setState({loading:false})
      var re = JSON.parse(nextProps.page_name.client_info)
      // console.log('response => ',re.error)
      if(re.error != null){
          let errors = {};
          if(this.state.client_info=="client_id"){
          document.getElementById("ClientId").value = ""; 
          document.getElementById("ClientLastname").value = "";                               
          this.state.formData['ClientId']="";
          this.state.formData['ClientLastname']="";
          errors["ClientId"] = "Please enter valid Client ID or last name";
          }else{
              errors["ClientFirstName"] = "Please enter valid Client ID or last name";
              this.state.formData['ClientFirstName']="";
              this.state.formData['Clientlastname']="";
              this.state.formData['ClientEmail']="";
              // this.state.formData['ClientDob']="";
              document.getElementById("ClientFirstName").value = ""; 
              document.getElementById("Clientlastname").value = "";    
              document.getElementById("ClientEmail").value = ""; 
              // document.getElementById("ClientDob").value = ""; 
                


          }
          this.setState({errors: errors});
      }else{
          this.setState({verify_client:true})
          this.setState({success_msg:"Client Details Successfully Confirmed"});  
      }     
      
  }



    }

  checkRadio3 = (e) => {     
      if(this.state.update_card){
          this.setState({update_card:!this.state.update_card})
          this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
          this.state.formData["CVV"] = this.state.card_info['CVV']
          this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
          this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
          this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
          this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']
      }
      else{
          this.state.formData["Credit_Card_Number"] = ""
          this.state.formData["CVV"] = ""
          this.state.formData['Credit_Card_Holder_First_Name'] = ""
          this.state.formData['Credit_Card_Holder_Last_Name'] = ""
          this.state.formData['Month_of_Expiry'] = ""
          this.state.formData['Year_of_Expiry'] = ""
          this.setState({update_card:true})
      }
      
      // console.log('update_card =>', this.state.update_card)
  }

  checkradio2 = (e) => {
    this.state.formData['client-info'] = e.target.id;
    this.setState({client_info:e.target.id})

    // console.log('client info = ', this.state.client_info)
    
}


checkPayOption = (e) => {
  if(e.target.id == "credit_card"){
    
    this.setState({ACH_payment:false})
    this.setState({credit_card:true})
    this.setState({CreditCard:true});
    this.setState({shipment:false});

    this.state.formData['CreditCard'] = true; 

    this.state.formData['PaymentMethod'] = 'Credit Card';
    this.state.formData["Credit_Card_Number"] = this.state.card_info['Credit_Card_Number']
    this.state.formData["CVV"] = this.state.card_info['CVV']
    this.state.formData["Credit_Card_Holder_First_Name"] = this.state.card_info['Credit_Card_Holder_First_Name']
    this.state.formData["Credit_Card_Holder_Last_Name"] = this.state.card_info['Credit_Card_Holder_Last_Name']
    this.state.formData["Month_of_Expiry"] = this.state.card_info['Month_of_Expiry']
    this.state.formData["Year_of_Expiry"] = this.state.card_info['Year_of_Expiry']
    this.state.formData['CreditCardProcessingFee'] = 0.03 * this.state.amount;
    this.state.formData['AnnualBankingCCProcessingFee'] = 0.03 * this.state.formData['Annual_Storage_Plan_Amount'];
    this.state.formData['BioInsuranceCCProcessingFee'] = 0.03 * this.state.formData['Banking_Plan_Amount'];
    

  }

  if(e.target.id == "shipment"){
      this.state.formData['CreditCard'] = false; 
      this.setState({credit_card:false})
      this.setState({ACH_payment:false})
      this.setState({CreditCard:false});
      this.setState({update_card:false})
      this.setState({shipment:true});
      this.state.formData['PaymentMethod'] = 'Check';
      this.state.formData['CreditCardProcessingFee'] = 0 * this.state.amount;
      this.state.formData['BioInsuranceCCProcessingFee'] = 0;
      this.state.formData['AnnualBankingCCProcessingFee'] = 0;



      // console.log('CreditCard =>', this.state.CreditCard)
  }

  if(e.target.id == "ACH_payment"){
      this.setState({ACH_payment:true})
      this.setState({credit_card:false})
      this.setState({shipment:false});
      this.setState({CreditCard:false});

      this.setState({update_card:false})
      

      this.state.formData['PaymentMethod'] = 'ACH Payment';
      this.state.formData["Credit_Card_Number"] = ""
      this.state.formData["CVV"] = ""
      this.state.formData['Credit_Card_Holder_First_Name'] = ""
      this.state.formData['Credit_Card_Holder_Last_Name'] = ""
      this.state.formData['Month_of_Expiry'] = ""
      this.state.formData['Year_of_Expiry'] = ""
      this.state.formData['CreditCardProcessingFee'] = 0 * this.state.amount;
      this.state.formData['AnnualBankingCCProcessingFee'] = 0;
      this.state.formData['BioInsuranceCCProcessingFee'] = 0;


      // console.log('CreditCard =>', this.state.CreditCard)
  }
  this.state.formData['Payment_Type'] = e.target.value; 

  this.setState({Payment_Type:e.target.value});
  console.log('amount ------ ',this.state.amount);



  console.log(' state val  ------ ', this.state);

  
}


checkProcessingfee = (e) =>{
  if(this.state.formData['Payment_Type'] == 'CC'){
    this.state.formData['CreditCardProcessingFee'] = 0.03 * this.state.amount;
    this.state.formData['AnnualBankingCCProcessingFee'] = 0.03 * this.state.formData['Annual_Storage_Plan_Amount'];
    this.state.formData['BioInsuranceCCProcessingFee'] = 0.03 * this.state.formData['Banking_Plan_Amount'];
    
  }else{
    this.state.formData['CreditCardProcessingFee'] = 0;
    this.state.formData['AnnualBankingCCProcessingFee'] = 0;
    this.state.formData['BioInsuranceCCProcessingFee'] = 0;


  }

  console.log('amount ------ ',this.state.amount);
}
  datechange1(e){
    // new Date(e);
    this.state.formData['Dob'] = Moment(e).format('MM/DD/YYYY');
    // console.log('selected date =>', this.state.formData['Dob']);
    this.setState({Dob:e})
}

  onSubmit = (e) => {              
    e.preventDefault();    
      
    let fields = this.state.formData;
    let errors = {};
    let formIsValid = true;
    // let scroll = {};
    const yOffset = -30;


    if(this.state.update_card == true){

      if(!fields["Credit_Card_Number"]){
          formIsValid = false;
          errors["Credit_Card_Number"] = "Please enter credit card number";
          let element = document.getElementById("Credit_Card_Number");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }

      if(fields["Credit_Card_Number"]) {
          let cardvalid = valid.number(fields["Credit_Card_Number"]);
          // console.log('cardvalid',cardvalid);
          if(!cardvalid.isValid){
              formIsValid = false;
              errors["Credit_Card_Number"] = "Invalid credit card number.";
              let element = document.getElementById("Credit_Card_Number");                
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
          }
      }

      if(!fields["Credit_Card_Holder_First_Name"]){
          formIsValid = false;
          errors["Credit_Card_Holder_First_Name"] = "Please enter first name";
          let element = document.getElementById("Credit_Card_Holder_First_Name");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }

      if(!fields["Credit_Card_Holder_Last_Name"]){
          formIsValid = false;
          errors["Credit_Card_Holder_Last_Name"] = "Please enter last name";
          let element = document.getElementById("Credit_Card_Holder_Last_Name");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }

      if(!fields["Month_of_Expiry"]){
          formIsValid = false;
          errors["Month_of_Expiry"] = "Cannot be empty";
          let element = document.getElementById("Month_of_Expiry");                
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({top: y, behavior: 'smooth'});
              element.className = "form-control error"
      } 

      // if(fields["Month_of_Expiry"]){

      //     let expMonth = valid.expirationMonth(fields["Month_of_Expiry"]);
      //     // console.log('expmonth',expMonth.isPotentiallyValid);
      //     if(!expMonth.isPotentiallyValid){
      //         formIsValid = false;
      //         errors["Month_of_Expiry"] = "Invalid credit Card Expiration Month";
      //         let element = document.getElementById("Month_of_Expiry");                
      //         const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      //     window.scrollTo({top: y, behavior: 'smooth'});
      //     element.className = "form-control error"
      //     }
      // }

      if(!fields["Year_of_Expiry"]){
          formIsValid = false;
          errors["Year_of_Expiry"] = "Cannot be empty";
          let element = document.getElementById("Year_of_Expiry");                
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({top: y, behavior: 'smooth'});
              element.className = "form-control error"
          } 

          // if(fields["Year_of_Expiry"]){
          //     let expirationYear = valid.expirationYear(fields["Year_of_Expiry"]);
          //     // console.log('expirationYear',expirationYear.isPotentiallyValid);
          //     if(!expirationYear.isPotentiallyValid){
          //         formIsValid = false;
          //         errors["Year_of_Expiry"] = "Invalid credit Card Expiration Year";
          //         let element = document.getElementById("Year_of_Expiry");                
          //         const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          //     window.scrollTo({top: y, behavior: 'smooth'});
          //     element.className = "form-control error"
          //     }
          // }

          // if(fields["Year_of_Expiry"] && fields["Month_of_Expiry"]){
          //     let expirationDate = valid.expirationDate(fields["Month_of_Expiry"]+"/"+fields["Year_of_Expiry"]);
          //     // console.log('expirationDate',expirationDate);
          //     if(!expirationDate.isPotentiallyValid){
          //         formIsValid = false;
          //         errors["Year_of_Expiry"] = "Something Wrong with Expiration Year";
          //         let element = document.getElementById("Year_of_Expiry");                
          //         const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          //         window.scrollTo({top: y, behavior: 'smooth'});

          //         errors["Month_of_Expiry"] = "Something Wrong with Expiration Month";
          //         let element1 = document.getElementById("Month_of_Expiry");                
          //         element1.scrollIntoView({behavior: 'smooth'});
          //         element.className = "form-control error"
          //     }
          // }

          if(!fields["CVV"]){
              formIsValid = false;
              errors["CVV"] = "Cannot be empty";
              let element = document.getElementById("CVV");                
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({top: y, behavior: 'smooth'});
              element.className = "form-control error"
          }  
          
          if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
              formIsValid = false;
              errors["CVV"] = "Invalid CVV code"; 
              let element = document.getElementById("CVV");                
              const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
              window.scrollTo({top: y, behavior: 'smooth'});   
              element.className = "form-control error"            
          }

  }
  
  if(this.state.verify_client==false && this.state.client_info !=""){
    formIsValid = false;
    errors["verify_client"] = "Please confirm client details";
    let element = document.getElementById("verify_client");                
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    // this.setState({isChecked: false});
    // element.className = "form-control error"
  }
  

  if(this.state.client_info ==""){
    formIsValid = false;
    errors["client-info"] = "Please Check ";
    let element = document.getElementById("full_contact");                
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
}

if(this.state.client_info=="client_id"){

    if(!fields["ClientId"]){
        formIsValid = false;
        errors["ClientId"] = "Please enter Client Id";
        let element = document.getElementById("ClientId");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }
    if(!fields["ClientLastname"]){
        formIsValid = false;
        errors["ClientLastname"] = "Please enter client last name";
        let element = document.getElementById("ClientLastname");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

}

if(this.state.client_info=="full_contact"){

    if(!fields["ClientFirstName"]){
        formIsValid = false;
        errors["ClientFirstName"] = "Please enter client first name";
        let element = document.getElementById("ClientFirstName");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

    if(!fields["Clientlastname"]){
        formIsValid = false;
        errors["Clientlastname"] = "Please enter client last name";
        let element = document.getElementById("Clientlastname");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

    if(!fields["ClientEmail"]){
        formIsValid = false;
        errors["ClientEmail"] = "Please enter client email";
        let element = document.getElementById("ClientEmail");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

    if(!fields["ClientDob"]){
        formIsValid = false;
        errors["ClientDob"] = "Please enter client Dob";
        let element = document.getElementById("ClientDob");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

    if(fields["ClientDob"]){
      const dateFormat = 'MM-DD-YYYY';
      const yr = new Date(fields["ClientDob"]).getFullYear();
      const toDateFormat = Moment(new Date(fields["ClientDob"])).format(dateFormat);
      let validDate = Moment(toDateFormat, dateFormat, true).isValid();
      if(!validDate || yr >= 2050){
          formIsValid = false;
          errors["ClientDob"] = "Invalid date ";
          let element = document.getElementById("ClientDob");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }
    }

}




if(!fields["Tissue_Info"]){
  formIsValid = false;
  errors["Tissue"] = "Please select sample type";
  let element = document.getElementById("Adipose");                
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({top: y, behavior: 'smooth'});
 
}


if(this.state.Payment_Type == "CC"){

  if(!fields["Credit_Card_Number"]){
      formIsValid = false;
      errors["Credit_Card_Number"] = "Please enter credit card number";
      let element = document.getElementById("Credit_Card_Number");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      element.className = "form-control error"
  }

  if(fields["Credit_Card_Number"]) {
      let cardvalid = valid.number(fields["Credit_Card_Number"]);
      // console.log('cardvalid',cardvalid);
      if(!cardvalid.isValid){
          formIsValid = false;
          errors["Credit_Card_Number"] = "Invalid credit card number.";
          let element = document.getElementById("Credit_Card_Number");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      element.className = "form-control error"
      }
  }
}

if(this.state.formData['Payment_Type'] == "ACH") {
 
      if(!fields["Account_Number"] || !this.state.card_info['Account_Number']){
          formIsValid = false;
          errors["Account_Number"] = "Please enter account number";
          let element = document.getElementById("Account_Number");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }
      if(!fields["Name_On_Account"] || !this.state.card_info['Name_On_Account']){
          formIsValid = false;
          errors["Name_On_Account"] = "Field cann't be empty";
          let element = document.getElementById("Name_On_Account");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }

      if(!fields["ABA_Routing_Number"] || !this.state.card_info['ABA_Routing_Number']){
          formIsValid = false;
          errors["ABA_Routing_Number"] = "Field cann't be empty";
          let element = document.getElementById("ABA_Routing_Number");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }
  

      if(!fields["Account_Number"] || !this.state.card_info['Account_Number']){
        formIsValid = false;
        errors["CreditCard"] = "Please store your ACH Details in the update payment information tab to use ACH Payment Method.";
        let element = document.getElementById("ACH_payment");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
  

}

   console.log('formIsValid --- ', formIsValid)

  this.setState({errors: errors});

  if(formIsValid){
      // this.setState({ loading: true }); 
      this.state.formData['amount'] = this.state.amount+this.state.formData['CreditCardProcessingFee'];
      const newList = this.state.formData;
      console.log("newlist=>", newList);
      this.props.StoragePlan(newList, this.props.history);
      this.setState({loading:true})
  }



  }

  datechange2(e){
    // new Date(e);
    this.state.formData['ClientDob'] = Moment(e).format('MM/DD/YYYY');
    // console.log('selected date =>', this.state.formData['ClientDob']);
    this.setState({ClientDob:e})
}



  StorangePlan = (e) => {
    
    if(e.target.value == "No Annual Storage"){
      this.setState({storage_plan:0})
      this.state.formData['amount'] = 0 + this.state.additional_plan;
      this.setState({amount: 0 + this.state.additional_plan})
      this.state.formData['Annual_Storage_Plan_Amount'] = 0
    }
    if(e.target.value == "5 YEAR STORAGE"){
      this.setState({storage_plan:1575})
      this.state.formData['amount'] = 1575 + this.state.additional_plan;
      this.state.amount = 1575 + this.state.additional_plan;
      // this.setState({amount: 1575 + this.state.additional_plan})

      this.state.formData['Annual_Storage_Plan_Amount'] = 1575
    }
    if(e.target.value == "10 YEAR STORAGE"){
      this.setState({storage_plan:2975})
      this.state.formData['amount'] = 2975 + this.state.additional_plan;
      this.state.amount = 2975 + this.state.additional_plan;
      // this.setState({amount: 2975 + this.state.additional_plan})

      this.state.formData['Annual_Storage_Plan_Amount'] = 2975

    }
    if(e.target.value == "18 YEAR STORAGE"){
      this.setState({storage_plan:5040})
      this.state.formData['amount'] = 5040 + this.state.additional_plan;
      this.state.amount = 5040 + this.state.additional_plan;
      // this.setState({amount: 5040 + this.state.additional_plan})

      this.state.formData['Annual_Storage_Plan_Amount'] = 5040

    }
    if(e.target.value == "25 YEAR STORAGE"){
      this.setState({storage_plan:6563})
      this.state.formData['amount'] = 6563 + this.state.additional_plan;
      this.state.amount = 6563 + this.state.additional_plan;
      // this.setState({amount: 6563 + this.state.additional_plan})

      this.state.formData['Annual_Storage_Plan_Amount'] = 6563

    }
    

    this.state.formData[e.target.id] = e.target.value; 
    this.state.StoragePlan = e.target.value;
    this.setState({StoragePlan:e.target.value})

    this.checkProcessingfee();

    console.log(this.state.formData)
    console.log(this.state.amount)


  }

  BankingPlan = (e) => {
    // if(e.target.value == "No Additional Plan"){
    //   this.setState({additional_plan:0})
    //   // this.state.formData['Bio_Insurance_Banking_Plans']=""
    //   this.state.formData['amount'] = 0;
    //   this.state.formData['Banking_Plan_Amount'] = 0
    // }

    if(e.target.value == "0"){
      this.state.vials_count = e.target.value;
      this.setState({additional_plan:0})
      this.state.formData['Bio_Insurance_Banking_Plans']=""
      this.state.formData['amount'] = 0;
      this.state.amount = 0;
      // this.setState({amount: 0 })

      this.state.formData['Banking_Plan_Amount'] = 0
      console.log('vials_count ----------- ',this.state.vials_count)
    }
    if(e.target.value == "BIO-INSURANCE 100"){
      this.setState({additional_plan:4250})
      this.state.formData['amount'] = this.state.storage_plan + 4250;
      this.state.amount = this.state.storage_plan + 4250;
      // this.setState({amount: this.state.storage_plan + 4250})

      this.state.formData['Banking_Plan_Amount'] = 4250

    }
    if(e.target.value == "BIO-INSURANCE 250"){
      this.setState({additional_plan:10000})
      this.state.formData['amount'] = this.state.storage_plan + 10000;
      this.state.amount = this.state.storage_plan + 10000;
      // this.setState({amount: this.state.storage_plan + 10000})

      this.state.formData['Banking_Plan_Amount'] = 10000

    }
    if(e.target.value == "BIO-INSURANCE 500"){
      this.setState({additional_plan:18750})
      this.state.formData['amount'] = this.state.storage_plan + 18750
      this.state.amount = this.state.storage_plan + 18750
      // this.setState({amount: this.state.storage_plan + 18750})

      this.state.formData['Banking_Plan_Amount'] = 18750

    }

    if(e.target.value != "" && e.target.value != "0" ){
      this.state.vials_count = e.target.value;
      this.state.formData['Bio_Insurance_Banking_Plans']="ACT Bio-Insurance"
      let vial_val = e.target.value;
      vial_val = vial_val-10;
      console.log('vials count  -----------------------',this.state.vials[vial_val].Vials)
      // this.state.formData['Vials'] = e.target.value;
      console.log('price -----------------------',this.state.vials[vial_val].Price)
      this.setState({additional_plan:this.state.vials[vial_val].Price})
      this.state.formData['amount'] = this.state.storage_plan + this.state.vials[vial_val].Price;
      this.state.amount = this.state.storage_plan + this.state.vials[vial_val].Price;
      // this.setState({amount: this.state.storage_plan + this.state.vials[vial_val].Price})

      this.state.formData['Banking_Plan_Amount'] = this.state.vials[vial_val].Price

    }
    
    this.checkProcessingfee();

    this.state.formData[e.target.id] = e.target.value;
    // console.log(this.state.formData)

  }

  LongevityPlan = (e) => {
    if(e.target.value == "No Plan"){
      this.setState({additional_plan:0})
      this.state.formData['amount'] = 0;
      this.state.amount = 0;
      // this.setState({amount: 0})
      
      this.state.formData['Banking_Plan_Amount'] = 0

    }
    if(e.target.value == "Longevity 100"){
      this.setState({additional_plan:2125})
      this.state.formData['amount'] = this.state.storage_plan + 2125;
      this.state.amount = this.state.storage_plan + 2125;
      // this.setState({amount: this.state.storage_plan + 2125})

      this.state.formData['Banking_Plan_Amount'] = 2125

    }
    if(e.target.value == "Longevity 250"){
      this.setState({additional_plan:5000})
      this.state.formData['amount'] = this.state.storage_plan + 5000;
      this.state.amount = this.state.storage_plan + 5000;
      // this.setState({amount: this.state.storage_plan + 5000})

      this.state.formData['Banking_Plan_Amount'] = 5000

    }
    if(e.target.value == "Longevity 500"){
      this.setState({additional_plan:9375})
      this.state.formData['amount'] = this.state.storage_plan + 9375
      this.state.amount = this.state.storage_plan + 9375
      // this.setState({amount: this.state.storage_plan + 9375})

      this.state.formData['Banking_Plan_Amount'] = 9375

    }
    this.checkProcessingfee();

    this.state.formData[e.target.id] = e.target.value;
    console.log(this.state.formData)
  }

  savecard = (e) => {
    if(this.state.save_card){
        this.setState({save_card:!this.state.save_card})
        this.state.formData['save_card'] = !this.state.save_card
    }
    else{
        this.setState({save_card:true})

        this.state.formData['save_card'] = true
    }
    console.log(this.state.formData);
  }

  saveAch = (e) => {
    if(this.state.save_ach){
        this.setState({save_ach:!this.state.save_ach})
        this.state.formData['save_ach'] = !this.state.save_ach
    }
    else{
        this.setState({save_ach:true})

        this.state.formData['save_ach'] = true
    }
}

  



  onChange = (e) => {  
    this.state.formData[e.target.id] = e.target.value; 
    // console.log('form vlaue=>', this.state.formData);
    if (e.target.name == "Tissue"){
      this.state.formData['Tissue_Info'] = e.target.value;
    }

    console.log('form data -------',this.state.formData);
}

checkAch = (e) => {
  this.setState({update_ach:!this.state.update_ach})
  

  if(this.state.update_ach){
    this.state.formData["Account_Number"] = this.state.card_info['Account_Number']
    this.state.formData["Name_On_Account"] = this.state.card_info['Name_On_Account']
    this.state.formData["ABA_Routing_Number"] = this.state.card_info['ABA_Routing_Number']
  }else{
    this.state.formData["Account_Number"] = ""
    this.state.formData["Name_On_Account"] = ""
    this.state.formData["ABA_Routing_Number"] = ""
    // this.setState({update_ach:true})

  }
  console.log('update_card =>', this.state.update_ach)


}



changePlan = (e) => {
  // console.log('plan name => ',e.target.value)
  if(e.target.value =="Longevity Plan"){
    this.setState({additional_plan:2125})    
    this.state.formData['amount'] = this.state.storage_plan + 2125;
    this.state.amount = this.state.storage_plan + 2125;
    // this.setState({amount: this.state.storage_plan + 2125})

    this.state.formData['Banking_Plan_Amount'] = 2125
    this.state.formData['Longevity_Banking_Plans']="Longevity 100"
    this.state.formData['Bio_Insurance_Banking_Plans']=""
    // console.log('plan price ', this.state.formData['Banking_Plan_Amount'])
  }else if(e.target.value=="Bio-Insurance Plan"){
    this.setState({additional_plan:4250})
    this.state.formData['Banking_Plan_Amount'] = 4250

    this.state.formData['amount'] = this.state.storage_plan + 4250;
    this.state.amount = this.state.storage_plan + 4250;
    // this.setState({amount: this.state.storage_plan + 4250})

    this.state.formData['Bio_Insurance_Banking_Plans']="ACT Bio-Insurance"
    this.state.formData['Longevity_Banking_Plans']=""
    // console.log('plan price ', this.state.formData['Banking_Plan_Amount'])
  }else{
    this.setState({additional_plan:0})
    this.state.formData['Banking_Plan_Amount'] = 0
    this.state.formData['amount'] = this.state.storage_plan + 0;
    this.state.amount = this.state.storage_plan + 0;
    // this.setState({amount: this.state.storage_plan + 0})

    this.state.formData['Bio_Insurance_Banking_Plans']=""
    this.state.formData['Longevity_Banking_Plans']=""
  }
  this.setState({plan:e.target.value})
  this.state.formData[e.target.id] = e.target.value;
  this.checkProcessingfee();
  console.log(this.state.formData);
}

verifyClient = (e) => {
  e.preventDefault();    
  // window.scrollTo(0, 500);
    
  let fields = this.state.formData;
  let errors = {};
  let formIsValid = true;
  // let scroll = {};
  const yOffset = -30;
  if(this.state.client_info=="client_id"){

  if(!fields["ClientId"]){
      formIsValid = false;
      errors["ClientId"] = "Please enter Client Id";
      let element = document.getElementById("ClientId");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      this.setState({isChecked: false});
      element.className = "form-control error"
  }
  if(!fields["ClientLastname"]){
      formIsValid = false;
      errors["ClientLastname"] = "Please enter client last name";
      let element = document.getElementById("ClientLastname");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      element.className = "form-control error"
      this.setState({isChecked: false});
  }
}




if(this.state.client_info=="full_contact"){

  if(!fields["ClientFirstName"]){
      formIsValid = false;
      errors["ClientFirstName"] = "Please enter client first name";
      let element = document.getElementById("ClientFirstName");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      element.className = "form-control error"
  }

  if(!fields["Clientlastname"]){
      formIsValid = false;
      errors["Clientlastname"] = "Please enter client last name";
      let element = document.getElementById("Clientlastname");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      element.className = "form-control error"
  }

  if(!fields["ClientEmail"]){
      formIsValid = false;
      errors["ClientEmail"] = "Please enter client email";
      let element = document.getElementById("ClientEmail");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      element.className = "form-control error"
  }

  if(fields["ClientEmail"] != "" && !validator.isEmail(fields["ClientEmail"]) ){
    formIsValid = false;
    errors["ClientEmail"] = "Please enter valid client email";
    let element = document.getElementById("ClientEmail");                
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    element.className = "form-control error"
}

  if(!fields["ClientDob"]){
      formIsValid = false;
      errors["ClientDob"] = "Please enter client Dob";
      let element = document.getElementById("ClientDob");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      element.className = "form-control error"
  }

}
  this.setState({errors: errors});

  // console.log('check value => ',this.state.isChecked)
  if(formIsValid){
      // this.setState({ loading: true });  

      this.setState({isChecked: true});
      if(this.state.client_info=="client_id"){
          var newList = {ClientId:fields["ClientId"],ClientLastname:fields["ClientLastname"]}
      }else{
          var newList = this.state.formData;
      }
     
     
      // console.log("newlist=>", newList);
      this.props.verifyClientData(newList, this.props.history);
      this.setState({loading:true})
      
  }




}

  nextpage = (e) => {

    e.preventDefault();    
    // window.scrollTo(0, 500);
      
    let fields = this.state.formData;
    let errors = {};
    let formIsValid = true;
    // let scroll = {};
    const yOffset = -30;

    if(fields["Annual_Storage_Plan"]=="No Annual Storage" && fields["Plan"]=="No additional Plan"){
      formIsValid = false;
      errors["Annual_Storage_Plan"] = "Please Select Any Annual Storage Plan";
      let element = document.getElementById("Annual_Storage_Plan");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      element.className = "form-control error"

    }

    if(this.state.verify_client==false && this.state.client_info !=""){
      formIsValid = false;
      errors["verify_client"] = "Please confirm client details";
      let element = document.getElementById("verify_client");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
      // this.setState({isChecked: false});
      // element.className = "form-control error"
    }
    
  
    if(this.state.client_info ==""){
      formIsValid = false;
      errors["client-info"] = "Please Check ";
      let element = document.getElementById("full_contact");                
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }

  if(this.state.client_info=="client_id"){

    if(!fields["ClientId"]){
        formIsValid = false;
        errors["ClientId"] = "Please enter Client Id";
        let element = document.getElementById("ClientId");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        this.setState({isChecked: false});
        element.className = "form-control error"
    }
    if(!fields["ClientLastname"]){
        formIsValid = false;
        errors["ClientLastname"] = "Please enter client last name";
        let element = document.getElementById("ClientLastname");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
        this.setState({isChecked: false});
    }
  }

  if(this.state.client_info=="full_contact"){

    if(!fields["ClientFirstName"]){
        formIsValid = false;
        errors["ClientFirstName"] = "Please enter client first name";
        let element = document.getElementById("ClientFirstName");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

    if(!fields["Clientlastname"]){
        formIsValid = false;
        errors["Clientlastname"] = "Please enter client last name";
        let element = document.getElementById("Clientlastname");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

    if(!fields["ClientEmail"]){
        formIsValid = false;
        errors["ClientEmail"] = "Please enter client email";
        let element = document.getElementById("ClientEmail");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

    if(!fields["ClientDob"]){
        formIsValid = false;
        errors["ClientDob"] = "Please enter client Dob";
        let element = document.getElementById("ClientDob");                
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        element.className = "form-control error"
    }

    if(fields["ClientDob"]){
      const dateFormat = 'MM-DD-YYYY';
      const yr = new Date(fields["ClientDob"]).getFullYear();
      const toDateFormat = Moment(new Date(fields["ClientDob"])).format(dateFormat);
      let validDate = Moment(toDateFormat, dateFormat, true).isValid();
      if(!validDate || yr >= 2050){
          formIsValid = false;
          errors["ClientDob"] = "Invalid date ";
          let element = document.getElementById("ClientDob");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }
    }

}

    

    this.setState({errors: errors});


    if(formIsValid){
    this.setState({page:e.target.id})
    window.scrollTo({top: "10px", behavior: 'smooth'});
    }
    
  }

    render() {
        if(!sessionStorage.getItem('physician_id') || sessionStorage.getItem('physician_id')==null) {           
          return <Redirect to="../" />
        }      
        return (
            <MainLayout>
            <Header />
            { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}
            <div className="main-content">
                    <Left activeMenu={"ACT-banking-storage-plans"}/>
                    <div class="right-section">
                    <div class="pagesubheader-wrap">
                        <div class="pagesubheader-row">
                            <div class="page-subheader-col-left">
                                <div class="title-avatar">
                                    <img src="images/physician-icon.svg" alt="" />
                                </div>
                                <div class="title-main">
                                    <span class="title-sub-text">Physician Portal</span>
                                    <h1>ACT Banking and Storage Plans</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inner-content-area">
                    {(this.state.payment_response)?
                        <div>
                            <h3 style={{paddingTop:"10px"}}><i>Please check your email for order confirmation </i></h3>
                        </div>:
                    <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                      <span style={{color: "red"}}>{this.state.api_error}</span>
                      <div className="summary-section-wrap">  
                        <div className="left-section-content">
                      
        {(this.state.page==0)?
        <div className="col-md-12">  
          <h2 className="title-2">Client Information</h2>
          <h3 className="title-3 mb-3">Please confirm client information before proceeding.</h3>

          <div className="choose-options-sec">
                <div className="custom-control custom-radio">
                <input type="radio" id="client_id" name="client-info" className="custom-control-input" onChange={this.checkradio2} />
                <label className="custom-control-label" for="client_id">Client ID and Last Name</label>
                </div>
                <div className="custom-control custom-radio">
                <input type="radio" id="full_contact" name="client-info" className="custom-control-input" onChange={this.checkradio2} />
                <label className="custom-control-label" for="full_contact">Client Full Contact Information</label>
                </div>
                <span style={{color: "red"}}>{this.state.errors["client-info"]}</span>
          </div>

          <div className="submission-detail-sec">
                                {(this.state.client_info=="client_id")?
                                
                                <div className="row">
                                    <br />
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Client ID <span className="required">*</span> </label>
                                        <input type="text" id="ClientId" value={this.state.formData['ClientId']} className="form-control" placeholder="Client ID" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientId"]}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Last Name<span className="required">*</span> </label>
                                        <input type="text" id="ClientLastname" value={this.state.formData['ClientLastname']} className="form-control" placeholder=""  onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientLastname"]}</span>
                                    </div>
                                </div>
                                </div>:""}

                                {(this.state.client_info=="full_contact")?
                                <div className="row">
                                    <br />
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Client First Name <span className="required">*</span> </label>
                                        <input type="text" name="ClientFirstName" value={this.state.formData['ClientFirstName']} id="ClientFirstName" className="form-control" placeholder="" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientFirstName"]}</span>

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Last Name<span className="required">*</span> </label>
                                        <input type="text" name="Clientlastname" value={this.state.formData['Clientlastname']} id="Clientlastname" className="form-control" placeholder="" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["Clientlastname"]}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Client Email Address <span className="required">*</span> </label>
                                        <input type="text" name="ClientEmail" value={this.state.formData['ClientEmail']} id="ClientEmail" className="form-control" placeholder="" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientEmail"]}</span>
                                        
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Date of Birth <span className="required">*</span> </label>
                                        {/* <input type="text" name="ClientDob" id="ClientDob" className="form-control" placeholder="Dob" onChange={this.onChange} /> */}
                                        <DatePicker id="ClientDob" className="form-control bggray" maxDate={new Date()} placeholderText="mm/dd/yyyy" selected={this.state.ClientDob}  onChange={date => this.datechange2(date)} /> <br />
                                        <span style={{color: "red"}}>{this.state.errors["ClientDob"]}</span>
                                    </div>
                                </div>
                                </div>:""}

                                {(this.state.client_info!="")?
                                <div className="form-group col-md-12">
                                <button type="button" className="btn btn-secondary" id="verify_client" onClick={this.verifyClient}>Confirm Client Details</button>
                                <br /><span style={{color: "red"}}>{this.state.errors["verify_client"]}</span>   
                                <span style={{color: "green",margin:"1%"}}> {this.state.success_msg}</span>                                 
                                </div>:""}

          </div>


        <div className="bio-insurance-sec storage-plan">
          <h3 className="title-2 mb-4">ANNUAL STORAGE PLANS</h3>
          <label>Choose your Annual Storage Plan:</label>
          <div className="row">
              <div className="col-md-5">
                <div className="form-group">
                  <select className="form-control" id="Annual_Storage_Plan" value={this.state.StoragePlan} onChange={this.StorangePlan}>
                    <option value="No Annual Storage">NO STORAGE PLAN – ANNUAL PAYMENT</option>
                    {/* <option value="Bio-Insurance Plan">ANNUAL PAYMENT $250</option> */}
                    <option value="5 YEAR STORAGE">5 YEAR STORAGE $1,575</option>
                    <option value="10 YEAR STORAGE">10 YEAR STORAGE $2,975</option>
                    <option value="18 YEAR STORAGE">18 YEAR STORAGE $5,040</option>
                    <option value="25 YEAR STORAGE">25 YEAR STORAGE $6,563</option>
                  </select>
                </div>
                <span style={{color: "red"}}>{this.state.errors["Annual_Storage_Plan"]}</span>
              </div>
              
            </div>
          <div className="glove-table mt-0">
            <table width="100%" className="table table-bordered">
              <thead>
                <tr><th>Annual Storage Fee Plans</th>
                  <th>Annual Payment</th>
                  <th>5 Year Storage</th>
                  <th>10 Year Storage</th>
                  <th>18 Year Storage</th>
                  <th>25 Year Storage</th>
                </tr>
              </thead>
              <tbody><tr>
                  <td>Storage Plan Total Cost</td>
                  <td>$0</td>
                  <td>$1,575</td>
                  <td>$2,975</td>
                  <td>$5,040</td>
                  <td>$6,563</td>
                </tr>
                <tr>
                  <td>First Year Annual Storage Included</td>
                  <td><span className="mdi mdi-check-bold" /></td>
                  <td><span className="mdi mdi-check-bold" /></td>
                  <td><span className="mdi mdi-check-bold" /></td>
                  <td><span className="mdi mdi-check-bold" /></td>
                  <td><span className="mdi mdi-check-bold" /></td>
                </tr>
                <tr>
                  <td>Annual Storage after Plan Expiration*</td>
                  <td>$350</td>
                  <td>$350</td>
                  <td>$350</td>
                  <td>$350</td>
                  <td>$350</td>
                </tr>
                <tr>
                  <td>Total Storage Fees Saved</td>
                  <td><b>$0</b></td>
                  <td><b>$175</b></td>
                  <td><b>$525</b></td>
                  <td><b>$1,260</b></td>
                  <td><b>$2,188</b></td>
                </tr>
                <tr>
                  <td>Pre-Paid Savings Discount</td>
                  <td>0.00%</td>
                  <td>10.00%</td>
                  <td>15.00%</td>
                  <td>20.00%</td>
                  <td>25.00%</td>
                </tr>
              </tbody></table>

              <p>*These storage plans are additive. If the Client would like to add years to their Annual Storage Plan, the Client may purchase an additional Annual Storage Plan upon availability.</p>
              <p><i>*this includes a 3% credit card transaction fee</i></p>
          
          </div>
          <div className="mb-0">
          <h3 className="title-2 mb-4">BANKING PLANS</h3>
            <label><strong>Please choose if you would like to add a Bio-Insurance </strong></label><br />
            {/* <label>Description of Bio-Insurance and Longevity plan</label> */}
            <p>The <strong> Bio-Insurance Plan</strong> purchases a certain amount of stem cells at a discount and assures that you have 
              <strong> cells available immediately</strong> in case of emergency or for any other uses. This is the best option for 
              <strong> significant savings on future stem cell orders</strong> and <strong>reassures that cells are accessible for immediate use.</strong>
            </p>
            {/* <p>
              The <strong>Longevity Plan</strong> assures that you have <strong>cells available immediately</strong> in case of emergency or for 
              any other uses. This is a more cost effective option to assure cells are available, without 
              paying for cells upfront.
            </p> */}
            <p>
              Both the <strong>Bio-Insurance</strong> allow you to <strong>access your cells immediately</strong> and use 
              <strong> ACT's VIP Courier Service</strong> for quicker and more reliable delivery (additional shipping fees apply).
            </p>
            <div className="row">
              <div className="col-md-5">
                <p>Please Choose if you would like to add a Bio-Insurance </p>
                <div className="form-group">
                  <select className="form-control" value={this.state.plan} id="Plan" onChange={this.changePlan}>
                    <option value="Bio-Insurance Plan">Bio-Insurance Plan</option>
                    {/* <option value="Longevity Plan">Longevity Plan</option> */}
                    <option value="No additional Plan">No additional Plan</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="plan-section-wrap">
            { (this.state.plan == "Bio-Insurance Plan")?
            <div>
              <h3 className="title-2">Bio-Insurance Plans</h3>
              <p>Please choose your Bio-Insurance Plan. <strong>The Bio-Insurance Plan purchases a 
                  certain amount of stem cells at a discount and assures that you 
                  have cells available immediately in case of emergency or for any other uses.</strong></p>
              <p>Each Bio-Insurance Plan represents the total amount of cells paid for and available immediately.</p>
              </div>:""}

              { (this.state.plan == "Longevity Plan")?
            <div>
              <h3 className="title-2">Longevity Plan</h3>
              
              <p> Please choose your Longevity Plan. <strong>The Longevity Plan assures that you have cells available immediately in case of emergency or for any other uses.</strong></p>
              <p>Each Longevity Plan represents the total amount of cells paid for and available immediately.</p>
              </div>:""}

              <div className="glove-table mt-0">
                { (this.state.plan == "Bio-Insurance Plan")?
                <div><div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                  <label>Number of Vial(s) Requested</label>

                  <select className="form-control" value={this.state.vials_count} id="Vials" onChange={this.BankingPlan}>
                      {/* <option value="0">No Additional Plan 0$</option> */}
                      {/* <option value="BIO-INSURANCE 100">BIO-INSURANCE 100 $4,250</option>
                      <option value="BIO-INSURANCE 250">BIO-INSURANCE 250 $10,000</option>
                      <option value="BIO-INSURANCE 500">BIO-INSURANCE 500 $18,750</option> */}
                      <option value="10">10 Vials - $4,250.00 </option>
                      <option value="11">11 Vials - $4,675.00 </option>
                      <option value="12">12 Vials - $5,100.00  </option>
                      <option value="13">13 Vials - $5,525.00 </option>
                      <option value="14">14 Vials - $5,950.00 </option>
                      <option value="15">15 Vials - $6,375.00 </option>
                      <option value="16">16 Vials - $6,800.00 </option>
                      <option value="17">17 Vials - $7,225.00 </option>
                      <option value="18">18 Vials - $7,650.00 </option>
                      <option value="19">19 Vials - $8,075.00 </option>
                      <option value="20">20 Vials - $8,500.00 </option>
                      <option value="21">21 Vials - $8,925.00 </option>
                      <option value="22">22 Vials - $9,350.00 </option>
                      <option value="23">23 Vials - $9,775.00 </option>
                      {/* <option value="24">24 Vials - $10,200.00 </option> */}
                      <option value="25">25 Vials - $10,000.00 </option>
                      <option value="26">26 Vials - $10,400.00 </option>
                      <option value="27">27 Vials - $10,800.00 </option>
                      <option value="28">28 Vials - $11,200.00 </option>
                      <option value="29">29 Vials - $11,600.00 </option>
                      <option value="30">30 Vials - $12,000.00 </option>
                      <option value="31">31 Vials - $12,400.00 </option>
                      <option value="32">32 Vials - $12,800.00 </option>
                      <option value="33">33 Vials - $13,200.00 </option>
                      <option value="34">34 Vials - $13,600.00 </option>
                      <option value="35">35 Vials - $14,000.00 </option>
                      <option value="36">36 Vials - $14,400.00 </option>
                      <option value="37">37 Vials - $14,800.00 </option>
                      <option value="38">38 Vials - $15,200.00 </option>
                      <option value="39">39 Vials - $15,600.00 </option>
                      <option value="40">40 Vials - $16,000.00 </option>
                      <option value="41">41 Vials - $16,400.00 </option>
                      <option value="42">42 Vials - $16,800.00 </option>
                      <option value="43">43 Vials - $17,200.00 </option>
                      <option value="44">44 Vials - $17,600.00 </option>
                      <option value="45">45 Vials - $18,000.00 </option>
                      <option value="46">46 Vials - $18,400.00 </option>
                      {/* <option value="47">47 Vials - $18,800.00 </option>
                      <option value="48">48 Vials - $19,200.00 </option>
                      <option value="49">49 Vials - $19,600.00 </option> */}
                      <option value="50">50 Vials - $18,750.00 </option>
                      <option value="51">51 Vials - $19,125.00 </option>
                      <option value="52">52 Vials - $19,500.00 </option>
                      <option value="53">53 Vials - $19,875.00 </option>
                      <option value="54">54 Vials - $20,250.00 </option>
                      <option value="55">55 Vials - $20,625.00 </option>
                      <option value="56">56 Vials - $21,000.00 </option>
                      <option value="57">57 Vials - $21,375.00 </option>
                      <option value="58">58 Vials - $21,750.00 </option>
                      <option value="59">59 Vials - $22,125.00 </option>
                      <option value="60">60 Vials - $22,500.00 </option>
                      <option value="61">61 Vials - $22,875.00 </option>
                      <option value="62">62 Vials - $23,250.00 </option>
                      <option value="63">63 Vials - $23,625.00 </option>
                      <option value="64">64 Vials - $24,000.00 </option>
                      <option value="65">65 Vials - $24,375.00 </option>
                      <option value="66">66 Vials - $24,750.00 </option>
                      <option value="67">67 Vials - $25,125.00 </option>
                      <option value="68">68 Vials - $25,500.00 </option>
                      <option value="69">69 Vials - $25,875.00 </option>
                      <option value="70">70 Vials - $26,250.00 </option>
                      <option value="71">71 Vials - $26,625.00 </option>
                      <option value="72">72 Vials - $27,000.00 </option>
                      <option value="73">73 Vials - $27,375.00 </option>
                      <option value="74">74 Vials - $27,750.00 </option>
                      <option value="75">75 Vials - $28,125.00 </option>
                    </select>

{/* 
                    <select className="form-control" id="Bio_Insurance_Banking_Plans" onChange={this.BankingPlan}>
                      <option value="BIO-INSURANCE 100">BIO-INSURANCE 100 $4,250</option>
                      <option value="BIO-INSURANCE 250">BIO-INSURANCE 250 $10,000</option>
                      <option value="BIO-INSURANCE 500">BIO-INSURANCE 500 $18,750</option>
                    </select> */}
                  </div>
                </div>
              </div>
                  
                <table width="100%" className="table table-bordered mb-0">
                  <thead>
                    <tr><th>Bio-Insurance Banking Plans</th>
                      <th>No Additional Plan</th>
                      <th>Bio-Insurance 100</th>
                      <th>Bio-Insurance 250</th>
                      <th>Bio-Insurance 500</th>
                    </tr>
                  </thead>
                  <tbody><tr>
                      <td>Bio-Insurance Plan Total Cost</td>
                      <td>$0</td>
                      <td>$4,250</td>
                      <td>$10,000</td>
                      <td>$18,750 </td>
                    </tr>
                    <tr>
                      <td>Cryo-preserved autologous Mesenchymal <br /> Stem Cells (MSCs)</td>
                      <td><span className="mdi mdi-check-bold" /></td>
                      <td><span className="mdi mdi-check-bold" /></td>
                      <td><span className="mdi mdi-check-bold" /></td>
                      <td><span className="mdi mdi-check-bold" /></td>
                    </tr>
                    <tr>
                      <td>Total Stem cells available upon <br /> immediate request </td>
                      <td><i>3 weeks notice required</i></td>
                      <td>100,000,000</td>
                      <td>250,000,000</td>
                      <td>500,000,000</td>
                    </tr>
                    <tr>
                      <td>Bio-Insurance Stem Cells Included</td>
                      <td>$0</td>
                      <td>100,000,000</td>
                      <td>250,000,000</td>
                      <td>500,000,000</td>
                    </tr>
                    <tr>
                      <td>Cost per 10mm Stem Cells After<br /> Bio-Insurance Cells</td>
                      <td>$500</td>
                      <td>$500</td>
                      <td>$500</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>Total Savings</td>
                      <td><b>$0</b></td>
                      <td><b>$750</b></td>
                      <td><b>$2,500</b></td>
                      <td><b>$6,250</b></td>
                    </tr>
                    <tr>
                      <td>Pre-paid Bio-Insurance Discount</td>
                      <td>0.00%</td>
                      <td>15.00%</td>
                      <td>20.00%</td>
                      <td>25.00%</td>
                    </tr>
                  </tbody></table>
                  <p>* The total amount of cells paid for and available will be reduced based on each Client order. If Client would like to replenish or add to their Bio-Insurance Plan, the Client may purchase an additional Bio-Insurance Plan upon availability.
</p>
                  </div>:""}

                  { (this.state.plan == "Longevity Plan")?
                  <div><div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <select className="form-control" id="Longevity_Banking_Plans" onChange={this.LongevityPlan}>
                        {/* <option value="No Plan"> No Plan 0$</option> */}
                        <option value="Longevity 100">Longevity 100 $2,125</option>
                        <option value="Longevity 250">Longevity 250 $5,000</option>
                        <option value="Longevity 500">Longevity 500 $9,375</option>
                      </select>
                    </div>
                  </div>
                </div>
                  <table width="100%" className="table table-bordered mb-0">
                  <thead>
                    <tr><th>Longevity Banking Plans</th>
                      <th>No Additional Plan</th>
                      <th>Longevity 100</th>
                      <th>Longevity 250</th>
                      <th>Longevity 500</th>
                    </tr>
                  </thead>
                  <tbody><tr>
                      <td>Bio-Insurance Plan Total Cost</td>
                      <td>$0</td>
                      <td>$2,125</td>
                      <td>$5,000</td>
                      <td>$9,375 </td>
                    </tr>
                    <tr>
                      <td>Cryo-preserved autologous Mesenchymal Stem Cells (MSCs)</td>
                      <td><span className="mdi mdi-check-bold" /></td>
                      <td><span className="mdi mdi-check-bold" /></td>
                      <td><span className="mdi mdi-check-bold" /></td>
                      <td><span className="mdi mdi-check-bold" /></td>
                    </tr>
                    <tr>
                      <td>Total Stem cells available upon immediate request </td>
                      <td><i>3 weeks notice required</i></td>
                      <td>10,000,000</td>
                      <td>25,000,000</td>
                      <td>50,000,000</td>
                    </tr>
                    <tr>
                      <td>Bio-Insurance Stem Cells Included</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>Cost per 10mm Stem Cells </td>
                      <td>$500</td>
                      <td>$500</td>
                      <td>$500</td>
                      <td>$500</td>
                    </tr>
                    {/* <tr>
                      <td>Total Savings</td>
                      <td><b>$0</b></td>
                      <td><b>$750</b></td>
                      <td><b>$2,500</b></td>
                      <td><b>$6,250</b></td>
                    </tr>
                    <tr>
                      <td>Pre-paid Bio-Insurance Discount</td>
                      <td>0.00%</td>
                      <td>15.00%</td>
                      <td>20.00%</td>
                      <td>25.00%</td>
                    </tr> */}
                  </tbody></table>
                  <p>* The total amount of cells paid for and available will be reduce based on each Client order. If Client would like to replenish or add to their Longevity Plan, the Client may purchase an additional Longevity Plan upon availability.</p>
                  </div>:""}



              </div>
            </div>
          </div>
        </div>
        <button type="button" onClick={this.nextpage} id="1" className="btn btn-primary">Next</button>
      </div>:
      <div>
         <div className="form-wrap glove-form">
      <div className="row">
      {/* <span style={{color: "red"}}>{this.state.api_error}</span> */}
      <div className="col-md-12 submission-option-detail">
          <h2 className="title-2">Physician Information</h2>
          <div class="submission-detail-sec">                                
         
      <div class="row">                          
      <div className="col-md-6">
          <div className="form-group">
          <label className="col-form-label">Physician ID <span className="required">*</span></label>
          <div className="field-col">
              <input type="text" id="PhysicianId" className="form-control" placeholder="" value={this.state.formData['Id']} disabled />
              <span style={{color: "red"}}>{this.state.errors["PhysicianId"]}</span>
          </div>
          </div>
      </div>
      <div className="col-md-6">
          <div className="form-group">
          <label className="col-form-label">Physician Last Name<span className="required">*</span></label>
          <div className="field-col">
              <input type="text" id="PhysicianLastName" className="form-control" placeholder="" value={this.state.formData['PhysicianLastName']}  disabled />
              <span style={{color: "red"}}>{this.state.errors["PhysicianLastName"]}</span>
          </div>
          </div>
      </div>                            
      </div>

      <div className="mid-sections">
        <h2 className="title-2 mb-4">Tissue Information</h2>
        <h3 class="title-3 mb-4">Please Select Sample Type :</h3>
        <div class="choose-options-sec">
        <div class="custom-control custom-radio">
        <input type="radio" id="Adipose" name="Tissue" value="Adipose" class="custom-control-input" onChange={this.onChange} />
        <label class="custom-control-label" for="Adipose">Adipose</label>
        </div>
        <div class="custom-control custom-radio">
        <input type="radio" id="Bone_marrow" name="Tissue" value="Bone Marrow" class="custom-control-input" onChange={this.onChange} />
        <label class="custom-control-label" for="Bone_marrow">Bone Marrow</label>
        </div>        
        <span style={{color: "red"}}>{this.state.errors["Tissue"]}</span>                                
        </div>

        </div>
     



          </div>
      


  

                            <h3 class="title-3 mb-4">Please Choose a Payment Method :</h3>
                            <i>*Important Payment Information:
                            A 3% credit card processing fee will be added to all transactions paid via credit card. To avoid this fee, you may choose to pay via ACH. Thank you for trusting us to safely store your personal stem cells!</i>
                            <p></p>
                            <div class="choose-options-sec">
                            <div class="custom-control custom-radio">
                            <input type="radio" id="credit_card" name="CreditCard" checked={this.state.Payment_Type === "CC"}  defaultChecked  class="custom-control-input" value="CC" onClick={this.checkPayOption} />
                            <label class="custom-control-label" for="credit_card">Credit Card</label>
                            </div>
                            {/*  <div class="custom-control custom-radio">
                            <input type="radio" id="shipment" name="CreditCard"  class="custom-control-input" value="Shipment" onClick={this.checkPayOption}/>
                            <label class="custom-control-label" for="shipment">Check</label>
                            </div>  */}
                            <div class="custom-control custom-radio">
                            <input type="radio" id="ACH_payment" name="CreditCard" checked={this.state.Payment_Type === "ACH"}  class="custom-control-input" value="ACH" onClick={this.checkPayOption}/>
                            <label class="custom-control-label" for="ACH_payment">ACH Payment</label>
                            </div>                                        
                            </div>
                            <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                            <br />

                          <div className="mid-sections">
                        <h2 className="title-2 mb-4">Payment Information</h2>
                        <div className="payment-info col-md-8" style={{float:'left'}}>
                        {(this.state.CreditCard)? 
                        <div className="card">
                            <div className="card-header">
                            <h4>Current Payment Method Secured For Annual Banking </h4>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck2" defaultChecked />
                                <label className="custom-control-label" htmlFor="customCheck2" />
                            </div>
                            </div>
                            <div className="card-body">
                            <div className="saved-card-detail">
                                <div className="row">
                                <div className="col-md-6">
                                    <label>Card Number</label>
                                    <div className="card-dtl-value">
                                    <img className="card-logo" src="images/master-card.png" alt="" />
                                    <span>Credit Card ending in {this.state.card_last_gid}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label>Name on card</label>
                                    <div className="card-dtl-value">
                                    <span>{this.state.card_info['Credit_Card_Holder_First_Name']} {this.state.card_info['Credit_Card_Holder_Last_Name']}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label>Expiry</label>
                                    <div className="card-dtl-value">
                                    <span>{this.state.card_info['Month_of_Expiry']} / {this.state.card_info['Year_of_Expiry']}</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <br />
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" id="update_card" name="update_card" className="custom-control-input" value="true" onClick={this.checkRadio3}/>
                                <label className="custom-control-label" for="update_card">Use Another Credit Card </label>
                              
                            </div>

                            {(this.state.update_card == true)?
                            <div className="card-edit-option">
                                <div className="card-detail-form">
                                <div className="card-detail-wrap mb-5">
                                    <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Credit Card Number <span className="required">*</span> </label>
                                        <input type="text" id="Credit_Card_Number" className="form-control" placeholder="" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                        <label>CVV <span className="required">*</span> </label>
                                        <input type="password" id="CVV" className="form-control" placeholder="***" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                        <label>Expiry Date <span className="required">*</span> </label>
                                        <input type="text" className="form-control" placeholder="-- --  /  -- -- -- --" />
                                        <span style={{color: "red"}}>{this.state.errors["CreditCard"]}</span>
                                        </div>
                                    </div> */}

                                <div className="col-md-6 mb-3">
                                <label htmlFor="Month_of_Expiry">Card Expiration Date <span className="act_required">*</span></label>
                                <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChange}  placeholder="mm" >
                                    <option value="">Select Month</option>
                                    <option value="01">01</option>
                                    <option value="02">02</option> 
                                    <option value="03">03</option> 
                                    <option value="04">04</option> 
                                    <option value="05">05</option>    
                                    <option value="06">06</option> 
                                    <option value="07">07</option> 
                                    <option value="08">08</option> 
                                    <option value="09">09</option> 
                                    <option value="10">10</option> 
                                    <option value="11">11</option>
                                    <option value="12">12</option>  
                                </select>
                                {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                <div className="invalid-feedback">
                                Valid Month required
                                </div>
                                </div>

                                    <div className="col-md-3 mb-3">
                                    <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                    <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChange}  placeholder="YYYY" >
                                    <option value="">Year</option>
                                    
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2030">2030</option>
                                    <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                    </select>
                                    {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                    
                                    <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                    Valid Year required
                                    </div>
                                    </div>

                                    
                                    <div className="col-md-12">
                                        <div className="card-logo-list">
                                        <div><img src="images/card-1.svg" alt="" /></div>
                                        <div><img src="images/card-2.svg" alt="" /></div>
                                        <div><img src="images/card-3.svg" alt="" /></div>
                                        <div><img src="images/card-4.svg" alt="" /></div>
                                        </div>
                                    </div>
                                    </div>
                                    <div>                                            
                                      <div className="custom-control custom-checkbox">
                                      <br />
                                      <input type="checkbox" id="save_card" name="save_card" className="custom-control-input" value="true" onClick={this.savecard}/>
                                      <label className="custom-control-label" for="save_card">Save this credit card on file for future payments</label>
                                      </div>
                                    </div>
                                </div>
                                <div className="card-detail-wrap">
                                    <h5>Billing Address <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title alt="" data-original-title="Tooltip Text" /></h5>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" id="Credit_Card_Holder_First_Name" className="form-control" placeholder onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" id="Credit_Card_Holder_Last_Name" className="form-control" placeholder onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Country</label>
                                        <input type="text" id="Credit_Card_Holder_Country" className="form-control" placeholder onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Zip/Postal Code</label>
                                        <input type="text" id="Credit_Card_Holder_Zip"  className="form-control" placeholder onChange={this.onChange} />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            :""}
                            {/* <div className="act-total">
                                Total: ${this.state.formData['amount']}.00
                            </div> */}

                            {/* <div className="act-total">
                            Annual Storage Plan: ${this.state.storage_plan}.00 + {this.state.formData['Plan']}: ${this.state.additional_plan}.00 = Total: ${this.state.formData['amount']}.00
                            </div> */}
                            

                            </div>
                        </div>:""}

                        {(this.state.Payment_Type == "ACH")?    
                             <div className="card">
                                <div className="card-header">
                                <h4>ACH Payment Method Secured For Banking </h4> 
                                <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck2" defaultChecked />
                                <label className="custom-control-label" htmlFor="customCheck2" />
                            </div>                                      
                                </div>  
                                <div className="card-body">
                                {(this.state.card_info['ABA_Routing_Number'] ||  this.state.card_info['Account_Number'])?

                                    <div>
                                    <div className="saved-card-detail">                     
                                    <div class="row">          
                                        {/* <h2 className="title-2">ACH Billing Information</h2> */}
                                        <div class="col-md-4">
                                        <label> Name On Account</label>
                                        <div class="card-dtl-value">
                                        <span>{this.state.card_info['Name_On_Account']}</span>
                                        </div>
                                        </div>             
                                        <div class="col-md-4">
                                        <label>Account Number</label>
                                        <div class="card-dtl-value">
                                        <span>Account Number Ending in {this.state.acc_num}
                                        </span>
                                        </div>
                                        </div>

                                        <div class="col-md-4">
                                        <label>ABA Routing Number</label>
                                        <div class="card-dtl-value"> 
                                        <span>{this.state.card_info['ABA_Routing_Number']}
                                        </span>
                                        </div>
                                        </div>
                                    
                                    
                                    </div>
                                    </div>
                                    <br />
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="update_ach" name="update_ach" className="custom-control-input" value="true" onClick={this.checkAch}/>
                                        <label className="custom-control-label" for="update_ach">Use Another ACH </label>
                                        
                                    </div>
                                    <br />
                                    <div lassName="custom-control">
                                        {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'])?
                                    <span><b style={{color: "red"}}>Please store your ACH Details in the <a className="updapte_pay" href="./payment-information">update payment information</a> tab to use ACH Payment Method.</b></span>

                                        :""}
                                    </div>
                                    </div>
                                  :""}


                                

                                    {(!this.state.card_info['ABA_Routing_Number'] ||  !this.state.card_info['Account_Number'] || this.state.update_ach)?
                                  
                                        <div className="">   
                                            <div className="card-detail-form">
                                            <div class="row">
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Name On Account <span class="required">*</span> </label>
                                                 <input type="text" id="Name_On_Account" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Name_On_Account"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>Account Number<span class="required">*</span> </label>
                                                 <input type="text" id="Account_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["Account_Number"]}</span>
                                             </div>
                                         </div>
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <label>ABA Routing Number <span class="required">*</span> </label>
                                                 <input type="text" id="ABA_Routing_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                 <span style={{color: "red"}}>{this.state.errors["ABA_Routing_Number"]}</span>
                                             </div>
                                         </div>
                                         <div className="col-md-12">                                            
                                                <div className="custom-control custom-checkbox">
                                                <br />
                                                <input type="checkbox" id="save_ach" name="save_ach" className="custom-control-input" value="true" onClick={this.saveAch}/>
                                                <label className="custom-control-label" for="save_ach">Save this ACH on file for future payments</label>
                                            
                                                </div>
                                        </div> 

                                        </div>
                                            </div>
                                        </div>
                                    :""}
                                </div>
                            </div>
                                :''}    

                        {(this.state.CreditCard == "false")?                           
                            <div class="row">          
                             {/* <h2 className="title-2">Billing Information</h2> */}
                                          
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Billing Email Address <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="BillingEmail" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["BillingEmail"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Full Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="BillingName" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["BillingName"]}</span>
                                </div>
                                </div>
                            </div>
                            
                                </div>
                          :''} 
        </div>

    <div class="order-summary col-md-4" style={{float:"left"}}>
                    <div class="order-summary-inner">
                        <div class="order-summary-row plan-font-size pb-4">
                        <div class="order-label">
                            <small>Order Summary</small>
                            <h2>Banking and Storage Plan</h2>
                        </div>

                        <div class="summary-value pt-40">
                            {/* Demo 01 */}
                        </div>
                        </div>

                        <div class="order-summary-row">
                        <div class="order-label">
                        {this.state.StoragePlan} Plan
                        
                        </div>
                        <div class="summary-value">
                        {this.numberFormat(this.state.storage_plan)}
                        </div>
                        </div>
                        
                        <div class="order-summary-row">
                        <div class="order-label">
                        {this.state.formData['Plan']} {(this.state.formData['Plan'] == 'Bio-Insurance Plan') ?  this.state.formData['Vials'] + ' Vial(s) ' : ""}
                        
                        </div>
                        <div class="summary-value">
                        {this.numberFormat(this.state.additional_plan)} 
                        </div>
                        </div>
                        <div class="order-summary-row">
                        <div class="order-label">
                        Processing Fee
                         </div>
                        <div class="summary-value">
                        {this.numberFormat(this.state.formData['CreditCardProcessingFee'])} 
                        </div>
                        </div>
                    <div class="order-summary-row total-saving">
                      <div class="order-label">
                       <i><img src="images/saving-icon.png" alt="" /></i>  Total Price
                      </div>
                      <div class="summary-value">
                      {this.numberFormat(this.state.storage_plan+this.state.additional_plan+this.state.formData['CreditCardProcessingFee'])}                     
                      </div>                      
                    </div>
                    
                  </div>
                  
       </div>


</div>

<div className="button-row">
          <button id="0" onClick={this.nextpage} type="button" className="btn btn-outline-primary mr-2">Previous Page</button>
          <button type="submit" className="btn btn-primary">Submit</button>
      </div> 
</div>






      </div>
       </div></div> }
       </div>
       {/* order summer */}
       </div>

       </form>
                    }
                </div>
            </div>

            </div>

            </MainLayout>
        );
    }
}

ActBankingStrorage.propTypes = {
  errors: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  StoragePlan: PropTypes.object.isRequired,
  getPhysician: PropTypes.object.isRequired,
  getPaymentInfo: PropTypes.object.isRequired,
  verifyClientData: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  page_name : state.pay,       
  errors: state.errors,
});


export default connect(mapStateToProps, {getPhysician,getPaymentInfo,StoragePlan,verifyClientData  })(ActBankingStrorage);
