import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	BrowserRouter,
	useHistory
} from 'react-router-dom';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer'


class Header extends Component {
  // var history = useHistory();

  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  handleOnAction (event) {
    // console.log('user did something', event)
  }

  handleOnActive (event) {
    console.log('user is active', event)
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle (event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
  }

  signout = (e) => {
    // const { history } = this.props;
    localStorage.setItem('physician_id',null)
    sessionStorage.clear();    
    console.log('logount call')
    window.location = '../';
    // history.push('../home')
  }
    render() {
      let activeMenu = this.props.activeMenu == undefined ? '' : this.props.activeMenu;
        return (
            <div className="main-header">
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                timeout={1000 * 60 * 5}
                // onActive={this.handleOnActive}
                onIdle={this.signout}
                // onAction={this.handleOnAction}
                debounce={250}
              />
            <div className="container-fluid">
            <div className="main-header-left">
              <div className="main-logo-area">
                <a href><img src="../images/logo-main.svg" alt="" /></a>
              </div> 
            </div>
            
            <div className="header-inner"> 

              <div className="main-title-top">
                Physician Portal
              </div>
  
              <div className="main-header-mid">
                {/* <div className="input-group">
                  <div className="input-group-prepend">
                    <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown">
                     <span>All</span>
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href>Link 1</a>
                      <a className="dropdown-item" href>Link 2</a>
                      <a className="dropdown-item" href>Link 3</a>
                    </div>
                  </div>
                  <div className="search-input">
                    <i><img src="images/icon_search.png" alt="" /></i>
                    <input type="search" className="form-control" placeholder="Search Data..." />
                  </div>
                </div> */}
              </div>
              <div className="main-header-right">
             
              <div className="settings-dropdown mr-3">
              <a className=" d-flex dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href ><img src="/images/menu.png" alt="" /></a>
                

                  <ul className="dropdown-menu">
                  <li  className={ activeMenu == 'patient-activity' ? "active" : 'dropdown-item'}><a  href="../patient-activity">Patient Activity</a></li>
                  <li className={ activeMenu == 'initial-processing' ? "active" : 'dropdown-item'}><a href="../initial-processing">New Sample Form </a></li>
                  <li className={ activeMenu == 'client-consent' ? "active" : 'dropdown-item'} ><a href="../client-consent">New Client Consent Form</a></li>
                  <li className={ activeMenu == 'infectious-disease' ? "active" : 'dropdown-item'}><a href="../infectious-disease">New Infectious Disease Form</a></li>
                  <li className={ activeMenu == 'ACT-banking-storage-plans' ? "active" : 'dropdown-item'}><a href="../ACT-banking-storage-plans">ACT Banking and Storage Plans</a></li>

                  <li className={ activeMenu == 'stem-cell-request' ? "active" : 'dropdown-item'}><a href="../stem-cell-request">New Stem Cell Request Form</a></li>
                  <li className={ activeMenu == 'store' ? "active" : 'dropdown-item'}><a href="../Store">ACT Store</a></li>

                  <li className={ activeMenu == 'payment-information' ? "active" : 'dropdown-item'}><a href="../payment-information">Update Payment Information</a></li>
                  <li className={ activeMenu == 'resources' ? "active" : 'dropdown-item'}><a href="../resources">Resources</a></li>
                  <li  className={ activeMenu == 'physician-information' ? " active" : 'dropdown-item'}><a href="../physician-information">Physician Information</a></li>
                
            </ul>
            </div>
                  {/* <div className="settings-dropdown mr-3">
                    <button type="button" className="dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="../images/setting-icon.svg" alt="" />
                    </button>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href>Test Link 1</a>
                      <a className="dropdown-item" href>Test Link 2</a>
                      <a className="dropdown-item" href>Test Link 3</a>
                      <a className="dropdown-item" href>Test Link 4</a>
                    </div>
                  </div> */}
                  <div className="notification-col main-profile-menu">
                    <a className="profile-user d-flex dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href ><img alt="" src="../images/avatar1.jpg" /></a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href="../physician-information"><i className="mdi mdi-account-circle-outline"></i>Profile</a>
                        <a className="dropdown-item" href="../physician-information"><i className="mdi mdi-cog-outline"></i>Edit Profile</a>
                        {/* <a className="dropdown-item" href><i className="mdi mdi-email-outline"></i>Messages</a>
                        <a className="dropdown-item" href><i className="mdi mdi-cog-outline"></i>Account Settings</a> */}
                        <a className="dropdown-item sign-out-link" onClick={this.signout} href><i className="mdi mdi-logout-variant"></i>Sign Out</a>
                    </div>
                  </div>
                </div>

                </div>
                </div>
              </div>
        );
    }
}

// Header.propTypes = {

// };

export default Header;